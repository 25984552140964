import React from 'react'


class Instructions extends React.Component {
  
    componentDidMount () {
    }
  
    componentWillUnmount () {

    }
  
 
    render () {
      return (
        <div className="instructions-container">
            <div>
            Click, Drag or Touch to turn the view.<br/>
            Use <span>W</span><span>A</span><span>S</span><span>D</span> or ↑→↓← to move.
            </div> 
        </div>

      )
    }
  }
  
  
  export default Instructions