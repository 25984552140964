import React , { Suspense }from 'react'



class Header extends React.Component {
  
    componentDidMount () {
    }
  
    componentWillUnmount () {
    }

    renderPositions = () => 
        Object.entries(this.props.position).map( (val) =>
            <div className="map-dot"
                key={val[0]}
                style={{
                    transform: "translate(" + (val[1].position.z * -5 + 300) + "px," + (val[1].position.x * -5 + 300) +"px)"
                }}
            >
                {val[1].name}
            </div>
        )
  
 
    render () {
        if(this.props.position) {
            return (
                <div className="map">
                    { this.props.position.x } { this.props.position.y } { this.props.position.z }
                    { this.renderPositions() }
                    {/* { this.props.position.each((player)=> {
                        return (
                            <div className="map-dot"
                            style={{
                                transform: "translate(" + (player.z * -10 + 100) + "px," + (player.x * -10 + 200) +"px)"
                            }}
                            >
                            </div>

                        )
                    })

                    } */}

                </div>
            )
        }
        return (
            <div className="map">

            </div>
        )
    }
  }
  
  
  export default Header