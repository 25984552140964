import React , { Suspense } from 'react'
import { Link } from "react-router-dom"


class Close extends React.Component {
  
    componentDidMount () {
    }
  
    componentWillUnmount () {

    }
  
 
    render () {
      return (
        <Link className="menu-button-close" to="/">
            <img src="./images/leavcat.jpg" />
            Close
        </Link>

      )
    }
  }
  
  
  export default Close