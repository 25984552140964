import React , { Suspense } from 'react'
import { Link } from "react-router-dom"


class Menu extends React.Component {
  
    componentDidMount () {
    }
  
    componentWillUnmount () {

    }
  
 
    render () {
      return (
        <Link className="menu-button" to="/about">
            <img src="./images/supportcat.jpg" />
            About
        </Link>

      )
    }
  }
  
  
  export default Menu