import React from 'react'
import { Link } from "react-router-dom"
import { Resizable, ResizableBox } from 'react-resizable';
// import 'emoji-mart/css/emoji-mart.css'
// import { Picker } from 'emoji-mart'

// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/database';


class Chat extends React.Component {

  constructor(props) {
        super(props)
        this.state = {
            input: '',
            messages: []
        }

        this.messages = this.props.firebase.database().ref('messages/')
        this.messages.limitToLast(10).on('child_added', (snapshot)=> {
            this.setState({ 
              messages: this.state.messages.concat([{
                ...snapshot.val(),
                key: snapshot.key
              }])
            })
        })

        this.messages.limitToLast(20).once('value', (snapshot)=> {
          //console.log(snapshot);
        })
    }
  
    componentDidMount () {
    }
  
    componentWillUnmount () {
    }

    handleChange = (e) => {
      this.setState({input: e.target.value});
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.input.length > 0 ) {

          this.messages.push({
              name: this.props.firebase.auth().currentUser.displayName,
              photoURL: this.props.firebase.auth().currentUser.photoURL,
              userId: this.props.firebase.auth().currentUser.uid,
              message: this.state.input,
              timestamp: this.props.firebase.database.ServerValue.TIMESTAMP
          }).then(()=>{
              this.setState({
                  input: ''
              })
          })
        }  
    }

    componentDidUpdate() {
      this.scrollToBottom();
    }

    scrollToBottom = () => {
        setTimeout(()=>{
            this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        }, 10)
    }

  
 
    render () {
      return (
        <div className="chat">
              <ResizableBox width={270} height={400} 
        minConstraints={[100, 100]} maxConstraints={[280, 1000]}
        resizeHandles={['n']}
        >
                 <div className="chat-messages">
            {this.state.messages.map((message) =>
                <Message key={message.key} message={message.message} name={message.name} photoURL={message.photoURL}/>
            )}
            <div style={{ float:"left", clear: "both" }}
                ref={(el) => { this.messagesEnd = el; }}>
            </div>
          </div>
          <div className="chat-input">
            <ChatInput type handleSubmit={this.handleSubmit} handleChange={this.handleChange} value={this.state.input}/>    
          </div>
          <div className="chat-status">
            <div className="chat-avatar">
              <img src={this.props.user.photoURL}></img>
            </div>
            <div className="chat-title">
                  <div>{this.props.user && <span> {this.props.user.displayName}</span>}</div>
                  <div>
                    <Link className='chat-link' to="/profile">Profile</Link>  
                    <button className='chat-link' onClick={this.props.logOut}>Log out</button>
                  </div>  
            </div>            
          </div>
    </ResizableBox>
        </div>
      )
    }
  }
  
  
  export default Chat




class ChatInput extends React.Component {
  constructor(props) {
    super(props)
  }
  
  componentDidMount () {
  }

  componentWillUnmount () {

  }


  render () {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <input className="chat-input-field" type="text" value={this.props.value} onChange={this.props.handleChange} />
        <input className="chat-input-submit" type="submit" value=" " />
      </form>
    
    )
  }
}

function Message(props) {
    return (
      <div>
        <div className="chat-message">
            <div className="chat-image">
                <img src={props.photoURL} />
            </div>
            <div className="chat-content">
                <div className="chat-name">{props.name}</div>
                <div className="chat-content-message">{props.message}</div>
            </div>
        </div>
      </div>
    )
}