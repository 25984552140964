import ReactDOM from 'react-dom'
import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import messagesStore from './messages.js'
import './_geochat.scss'


class Chat extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            timer: 0,
            messages: [],
            typing: true,
            messagesStore: messagesStore
        }
    }

    tick() {
        if (this.state.messagesStore) {
            if (this.state.typing) {
                let message = this.state.messagesStore[this.state.timer % this.state.messagesStore.length]
                let sliced = this.state.messages
                if (this.state.messages.length > 6) {
                                    sliced = this.state.messages.slice(1)
                }
                this.setState(prevState => ({
                    messages: [...sliced, message],
                    typing: false,
                    timer: prevState.timer + 1
                }))
            } else {
                this.setState(prevState => ({
                    typing: true
                }))
            }
        }
        this.interval = setTimeout(() => this.tick(), 2000)
    }

    componentDidMount() {
        this.interval = setTimeout(() => this.tick(), 1000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        const messages = this.state.messages
        const isTyping = this.state.typing
        const listItems = messages.map((message, i) => (
            <CSSTransition
                key={message.content.toString()}
                classNames="example"
                unmountOnExit
                timeout={{ enter: 500, exit: 1000 }}
            >
                <MessageType key={ message.content.toString() } message={ message } />
            </CSSTransition>
            )
        )

        return (
            <div>
                <TransitionGroup>
                    { listItems }
                </TransitionGroup>
                {isTyping ? <Typing/> : '' }
            </div>
        )
    }
}

function MessageType(props) {
    if (props.message.type === 'text') {
        return (
            <TextMessage text={ props.message.content } />
        )
    }

    if (props.message.type === 'image') {
        return (
            <ImageMessage text={ props.message.content } />
        )
    }
}

function TextMessage(props) {
 return (
    <div className='message-text'>{ props.text }</div>
    )
}

function ImageMessage(props) {
 return (
    <div className='message-image'>
        <img className="lazyload" src={ props.text } />
    </div>
    )
}

function Typing() {
    return (
        <div className="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default Chat;
