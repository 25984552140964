import React , { Suspense }from 'react'
import { Link } from "react-router-dom"
// const Status = React.lazy(() => import('./Status'));
import Close from './CloseButton'


class About extends React.Component {
  
    componentDidMount () {
    }
  
    componentWillUnmount () {

    }
  
 
    render () {
      return (
        <div className="about">
          <Close />
          <div className="about-col">
             <h1>Playbour City Engines 1.02</h1>
              <div>
                CHAPTER I.<br/> <br/>
                Transaction Party<br/>
                <i>with Yessica Deira</i><br/>
                <p className="emphase">17 June 2020 8pm CEST</p> 
                <p><span className="dot">●</span> LIVE</p>
              </div>
              <div>
                
                CHAPTER II.<br/><br/>
                Elephant in the Room<br/>
                <i>in collaboration with <a href="https://schoolofspeculation.xyz/">School of Speculation, London</a></i><br/>
                <a className="about-link" href="https://sos.therodina.com/ ">Visit at sos.therodina.com</a>
              </div>
              <div>
              <br/>
              NEXT <br/> <br/>
              CHAPTER III.<br/>
              Transforming Desires with Handi Kim<br/>
              3 December 2020 7pm CEST<br/>
              <a className="about-link" href="https://desires.tetem.nl/">Visit at desires.tetem.nl</a>
              </div>
              <div>
              CHAPTER IV.<br/> <br/>
              Surprise and Adventure: When Reality Becomes Like a Game<br/>
              23 February 2021 7:30pm CEST<br/>
              <a className="about-link" href="https://us02web.zoom.us/j/84598726131">Zoom Link</a>
              </div>
        </div>
         <div className="about-col logn-text">
            <p>Welcome to the online exhibition Playbour City Engines, which explores how PLAY shifted into PLAYBOUR (play & labour and gamified production). It aims to reflect the fundamental transformation of free creative activities, personal or community realisations, and public playgrounds to private virtual spaces, unpaid labour, surveillance, and data extraction.</p>
            <p>In CHAPTER I. Yessica Deira and Tereza Ruller perform a Transaction Party, an auction-like event inspired by mighty Pearl Parties - jewellery selling events taking place as streaming on facebook. In Playbour City Engines, you can bid up and buy a unique playground. All raised money will go to funds supporting the ones in need.</p>
            <p>Next to four chapters of growing installation, the exhibition features an interview with Ivory Coast-based visual maker Yohanne Marie Solange Simeone aka Artist X who researched play memories of Abidjan children in collaboration with Fregik (Gueu Franck Eric) and Projet Bleu.</p>
            <p>Fascinated by seeing the city as a playground—as a result of automation, gamification, and data-collection—we are focused on the recent transformation of public spaces into sites of play. What used to be regarded as wholly outside the bounds of work—free time, family time, and leisure—have been instrumentalized by digital production on various platforms.</p> 
            <p>If we think of cities as ecologies increasingly dependent on automation, then all citizens are slowly turned to the workers-playbourers producing information, data, and images. This fundamental shift in the role of the inhabitants is changing the city itself to a network of various sensors, capture devices and underlying infrastructures. What is happening with the play, playfulness and playgrounds in an environment where an interaction between the city space, its inhabitants, and services is becoming digital and even virtual?</p>
            <p>We would like to express our thanks to Ella Buzo, 	Wilja Jurg, Yessica Deira, Yochanna Didi, Ventolin, Lukas Likavcan, Gideon Kiers, Annie Goodner, Pierre Shaw, Kishan San and many others.</p>
            <p>Playbour City Engines was commissioned by Tetem.</p>
            <p>Supported by Mondriaan Fonds, Stimuleringsfonds, Gemeente Enschede and Provincie Overijssel.</p>
          </div>
        </div>

      )
    }
  }
  
  
  export default About