const messagesStore = [
    {type: 'text', content: ' 🙂 Be patient'},
    {type: 'image', content: '/images/02.jpg'},
    {type: 'text', content: 'Why would you turn play into work?'},
    {type: 'text', content: '???'},
        {type: 'text', content: 'Are you accelerated?'},
    {type: 'image', content: '/images/01.jpg'},
    {type: 'text', content: 'Play Redefines Cities'},
    {type: 'text', content: 'Reimagine Citizenry?'},
    {type: 'image', content: '/images/03.jpg'},
    {type: 'text', content: 'All Dear Technofossils'},
    {type: 'text', content: 'All Dear Data'},
    {type: 'text', content: 'Autonomous Sea WIFI Waves'},
    {type: 'text', content: 'Surveillance, ㋡ Productivity, Reputation …'},
    {type: 'text', content: 'I am the Voice'},
    {type: 'text', content: 'of'},
    {type: 'text', content: 'plork!'},
    {type: 'image', content: '/images/05.jpg'},
    {type: 'text', content: 'Cloud Compute or Couldn’t'},
    {type: 'text', content: 'be faster'},
    {type: 'text', content: 'be somewhere else'},
    {type: 'text', content: 'Surface Stories Go Viral'},
    {type: 'text', content: 'world to which we can escape'},
    {type: 'image', content: '/images/09.jpg'},
    {type: 'text', content: 'Reservoirs of Imagination'},
    {type: 'text', content: 'No Gravity – No Teardrop'},
    {type: 'image', content: '/images/04.jpg'},
    {type: 'text', content: 'Hi!  ¯\_(ツ)_/¯'},
    {type: 'text', content: 'still watching me?'},
    {type: 'text', content: 'Loner'},
    {type: 'text', content: 'Forever Old'},
    {type: 'text', content: 'I am here'},
    {type: 'text', content: 'Evolution and Entertainment'},
    {type: 'text', content: 'Space Jam on the Orbit'},
    {type: 'image', content: '/images/06.jpg'},
    {type: 'text', content: 'Artificial Stellite Trash'},
    {type: 'text', content: 'Politics of Information Society'},
    {type: 'image', content: '/images/08.jpg'},
    {type: 'text', content: 'reality becomes the playground of the virtual'},
]

export default messagesStore


// Be patient, depending on your device and internet connection, it may take a while to load the full environment...