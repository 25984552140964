import './style/style.scss'

import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App'

import { Notifier } from '@airbrake/browser';

const airbrake = new Notifier({
  projectId: 276316,
  projectKey: 'cbfbdbb9093484e414af00ea3b15a7e6',
  environment: 'production'
});


ReactDOM.render (
    <App />,
    document.getElementById('root')
)
