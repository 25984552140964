import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '70%',
    height: 60,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#FF4856',
    'box-shadow': 'inset 0px 0px 10px rgba(0, 0, 0, 0.5)'
  },
  bar: {
    borderRadius: 0,
    backgroundColor: '#6F997E',
  },
}))(LinearProgress);

class Loading extends React.Component {
  
    componentDidMount () {
    }
  
    componentWillUnmount () {

    }
  
 
    render () {
      return (
          <div className="splashScreen">
            <div className="splashScreen-title">
            <BorderLinearProgress />
               {/* <img src="./images/loading.jpg"/> */}
            </div>
          </div>
      )
    }
  }
  
  
  export default Loading