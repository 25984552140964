import React, { Suspense } from 'react'

import {
    Router as Router,
    Switch,
    Route,
    Link,
    Redirect,
} from "react-router-dom"

import { CSSTransition } from 'react-transition-group'
import { createBrowserHistory } from "history";

import FirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

import LandingPage from './LandingPage'
// import Support from './Support'
// import SupportButton from './SupportButton'
import MenuButton from './MenuButton'
import Header from './Header'
import Lobby from './scenes/Lobby'
import Profile from './Profile'
import About from './About'
import Privacy from './Privacy'
// import Instructions from './Instructions'
import Map from './Map'
import Chat from './Chat'
// import Cinema from './Cinema'

// import FirstStage from './scenes/FirstStage'
// import SecondStage from './scenes/SecondStage'
// import Booth from './scenes/Booth'
// import SoundWalk from './scenes/SoundWalk'
// import { values } from 'underscore';

const config = {
    apiKey: 'AIzaSyA05yf83kIbhJ36FWHpjJP2dRW9dn3NkGQ',
    authDomain: 'tetem-playbour.firebaseapp.com',
    databaseURL: "https://tetem-playbour.firebaseio.com/",
    storageBucket: 'gs://tetem-playbour.appspot.com'
};

firebase.initializeApp(config);


const history = createBrowserHistory();

class App extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            user: null,
            volume: 1,
            login: false,
            rooms: {
                cinema: {message: "", open: false},
                djvj: {message: "", open: false},
                room1: {message: "", open: false},
                room2: {message: "", open: false},
                soundwalk: {message: "", open: false},
            },
            displayInstructions: true,
            flash: 'XOXO'
        }


        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    user: user,
                    login: false
                })
                let dbUser = firebase.database().ref('users/' + firebase.auth().currentUser.uid)
                dbUser.set({
                    username: firebase.auth().currentUser.displayName,
                })
                dbUser.onDisconnect().remove()
                
            } else {
                this.setState({login: true})
            }
        })

        this.rooms = firebase.database().ref('rooms/')
        this.rooms.on('value', (snapshot)=> {
            this.setState({rooms: snapshot.val()})
        })
        this.flash = firebase.database().ref('flash/')
        this.flash.on('value', (snapshot)=> {
            this.setState({flash: snapshot.val().message})
        })
    }

    uiConfig = {
        signInFlow: 'popup',
        credentialHelper: 'none',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: result => {
            }
        }
    }


    componentDidMount() {
    }

    componentWillUnmount() {
    }

    // menuToggle = () => {
    //     this.setState((state) => {
    //         return { isMenuOpen: !state.isMenuOpen }
    //     })
    // }
    handleInstructions = () => {
        this.setState({displayInstructions: false})
    }

    handleMute = () => {
        this.state.volume === 0 ? this.setState({volume: 1}) : this.setState({volume: 0})
    }

    handleUserUpdate = () => {
        this.state.user.reload().then((updatedUser)=> {

            this.setState({
                user: firebase.auth().currentUser
            })
        })
    }

    getVolume = () => {
        return this.state
    }

    render() {
        return (
            <Router history={history}>
                <div className="main-container">
                    <div className="header-container">
                        <CSSTransition in={this.state.user != null} timeout={5000} classNames="splashScreen-animation" unmountOnExit>
                            {this.state.user ? 
                            <div>
                                <Header 
                                    // handleOnClick={this.menuToggle}
                                    volume={this.state.volume} 
                                    handleMute={this.handleMute} 
                                    rooms={this.state.rooms}
                                    flash={this.state.flash}
                                />
                                <Chat 
                                    firebase={firebase}
                                    user={this.state.user}
                                    logOut={() => {
                                        firebase.auth().signOut()
                                        this.setState({user: null})
                                    }
                                }
                                 />
                            </div>
                            : <div />}
                        </CSSTransition>
                    </div> 
                    <Switch location={location}>
                        <PrivateRoute path="/lobby" user={this.state.user}>
                                <Lobby 
                                    getVolume={this.getVolume}
                                    firebase={firebase}
                                    user={this.state.user}
                                    history={history}
                                    sceneFile="lobby_01.babylon"
                                    handleInstructions = {this.handleInstructions}
                                    displayInstructions={this.state.displayInstructions}
                                />
                        </PrivateRoute>        
                        <PrivateRoute path="/profile" user={this.state.user}>
                                <Profile 
                                    firebase={firebase} 
                                    user={this.state.user} 
                                    handleUserUpdate={this.handleUserUpdate}
                                    history={history}
                                />
                        </PrivateRoute>

                        <Route path="/about">
                            <About />
                        </Route>
                        <Route path="/privacy">
                            <Privacy />
                        </Route>
                        <Route path="/">
                            {this.state.login &&
                                <div className="auth">
                                    <div className="auth-title">Sign in to enter the exhibition.</div>
                                    <FirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
                                </div>
                            }
                            <LandingPage />
                            {this.state.user ? <Redirect to="/lobby" /> : <LandingPage />}
                        </Route>
                    </Switch>
                    <MenuButton />

                </div>
            </Router>
        )

    }
}



function PrivateRoute(props) {
    return (
        <Route
            render={({ location }) =>
                props.user ? (
                    props.children
                ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    );
}

export default App

